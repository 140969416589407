import React from "react";
import { ContentHeader, Header } from "../../Components/header";
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { MdEdit } from "react-icons/md";
import { deepOrange } from '@mui/material/colors';

import { styled } from "@mui/material/styles";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}



export default function Orders() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-ful">
      <ContentHeader />
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h4 className="my-4">My Profile</h4>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="My Profile" {...a11yProps(0)} />
                <Tab label="Whatsapp" {...a11yProps(1)} />
                <Tab label="Support" {...a11yProps(2)} />
                <Tab label="Setting" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <div className="card profile-card border-0 py-5">
                <h4 className="mb-5">Profile Information</h4>
                <h5 className="mb-4">Profile</h5>
                <div className="d-flex align-items-center gap-5 mb-4">
                  <Stack direction="row" spacing={2}>
                    <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>
                  </Stack>
                  <Button className="upload-profile-btn">Upload Photo</Button>
                </div>
                <div className="position-relative mb-2">
                  <label className="profile-label mb-0">Display Name</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Display Name" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-2">
                  <label className="profile-label mb-0">Email</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Email" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-2">
                  <label className="profile-label mb-0">Phone No</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Phone No" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-2">
                  <label className="profile-label mb-0">Address</label>
                  <div>
                    <textarea rows={3} className="profile-input w-100" placeholder="Address"></textarea>
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-2">
                  <label className="profile-label mb-0">Post Code</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Post Code" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
               <div className="mt-2"><Button className="upload-save-btn">Save</Button></div> 
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
            <div className="card profile-card border-0 py-5">
                <h3 className="mb-5">Profile Information</h3>
                <h5 className="mb-4">Profile</h5>
                <div className="d-flex align-items-center gap-5 mb-4">
                  <Stack direction="row" spacing={2}>
                    <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>
                  </Stack>
                  <Button className="upload-profile-btn">Upload Photo</Button>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Display Name</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Display Name" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Email</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Email" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Phone No</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Phone No" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Address</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Address" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Post Code</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Post Code" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
               <div className="mt-2"><Button className="upload-save-btn">Save</Button></div> 
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
            <div className="card profile-card border-0 py-5">
                <h3 className="mb-5">Profile Information</h3>
                <h5 className="mb-4">Profile</h5>
                <div className="d-flex align-items-center gap-5 mb-4">
                  <Stack direction="row" spacing={2}>
                    <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>
                  </Stack>
                  <Button className="upload-profile-btn">Upload Photo</Button>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Display Name</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Display Name" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Email</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Email" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Phone No</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Phone No" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Address</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Address" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Post Code</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Post Code" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
               <div className="mt-2"><Button className="upload-save-btn">Save</Button></div> 
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
            <div className="card profile-card border-0 py-5">
                <h3 className="mb-5">Profile Information</h3>
                <h5 className="mb-4">Profile</h5>
                <div className="d-flex align-items-center gap-5 mb-4">
                  <Stack direction="row" spacing={2}>
                    <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>
                  </Stack>
                  <Button className="upload-profile-btn">Upload Photo</Button>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Display Name</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Display Name" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Email</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Email" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Phone No</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Phone No" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Address</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Address" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
                <div className="position-relative mb-3">
                  <label className="profile-label mb-3">Post Code</label>
                  <div>
                    <input className="profile-input w-100" placeholder="Post Code" />
                  </div>
                  <div className="profile-edit-icon"><MdEdit /></div>
                </div>
               <div className="mt-2"><Button className="upload-save-btn">Save</Button></div> 
              </div>
            </CustomTabPanel>
          </div>
        </div>
      </div>
    </div>
  );
}