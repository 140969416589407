

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import logger from 'redux-logger';

const middleware = (getDefaultMiddleware) => {

  if (process.env.NODE_ENV === 'development') {
    console.log("check dev",process.env.NODE_ENV)
    return getDefaultMiddleware().concat(logger);
  }
  return getDefaultMiddleware(); // No logger in production
};
const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;