import { combineReducers } from 'redux';
// import counterReducer from './product';
import productReducer from './product';
import invoiceReducer from './invoices';

// Combine Reducers
const rootReducer = combineReducers({
    product: productReducer,
    invoice:invoiceReducer,
    // Add other reducers here if you have more
  });
  
  export default rootReducer;