import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Badge from '@mui/material/Badge';
// import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import Cart from "./cart";
import Invoices from "./invoices";
import Analytics from "../Analytics/Analysis";
import Orders from "./orders";
import { ContentHeader, Header, Overlaydivsection } from "../../Components/header";
import {getProducts,getOrderIds,getClientDetails,getSuggesstions} from "../../services/Actions/client";
import Stepper from "./stepper";

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        props.onClick(event, props.value);
      }}
      {...props}
    />
  );
}

 function Dashboard(props) {
  const [value, setValue] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const handleChange = (newValue) => {
    props.router.navigate('/dashboard/'+newValue, { replace: true });
    setValue(newValue);
  };
  useEffect(()=>{
    props.dispatch(getClientDetails());
    props.dispatch(getSuggesstions());
    props.dispatch(getProducts());
    props.dispatch(getOrderIds());
      },[])
 useEffect(()=>{
    let y= props.params;
 });
 useEffect(() => {
  setValue(props.router.params.tab);
  let list = props.products;
  const newRows= list.filter((item) =>
    item.qty!=0
  );
  setCount(newRows.length??0)
}, [props]);
  return (
    <div className="w-full">
          <Overlaydivsection />
          <ContentHeader />
          <Header />
          <Stepper />
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-12">
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                // onChange={handleChange}
                aria-label="nav tabs example"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 d-flex align-items-center justify-content-evenly" style={{paddingTop: "20px"}}>
                      <LinkTab
                        label="Orders"
                        className={`nav-button-section ${
                          value == 0 ? "active" : ""
                        }`}
                        // href="/orders"
                        value={0}
                        onClick={()=>handleChange(0)}
                      />
                       <Badge badgeContent={count} color="secondary" 
                       sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: "#f16870", // Change the background color
                          color: "white", // Change the text color
                        }
                      }}>
                      <LinkTab
                        label="Cart"
                        className={`nav-button-section ${
                          value == 1 ? "active" : ""
                        }`}
                        // href="/cart"
                        value={1}
                        onClick={()=>handleChange(1)}
                      />
                      </Badge>
                      <LinkTab
                        label="Invoices"
                        className={`nav-button-section ${
                          value == 2 ? "active" : ""
                        }`}
                        // href="/invoices"
                        value={2}
                        onClick={()=>handleChange(2)}
                      />
                      <LinkTab
                        label="Analytics"
                        className={`nav-button-section ${
                          value == 3 ? "active" : ""
                        }`}
                        // href="/analytics"
                        value={3}
                        onClick={()=>handleChange(3)}
                      />
                    </div>
                  </div>
                </div>
              </Tabs>
            </Box>
          </div>
        </div>
      </div>
      {value ==0? <Orders />:
      value ==1? <Cart />:
      value ==2? <Invoices />:
      <Analytics />}
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    orderIds: state.product.orderIds??[],
    products: state.product.products??[]

  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));

