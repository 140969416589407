const initialState = {
    invoices: [],
    dayConsumption: {},
    dayExpenditure: {},
    monthExpenditure: {},
    monthConsumption: {},
    horizontalExpenditure:{},
    horizontalConsumption:{}
  };
  
  const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_INVOICES":
        return Object.assign({}, state, {
            invoices: action.data,
        });
        break;
        case "GET_CONSUMPTION_VERTICAL":
        return Object.assign({}, state, {
            dayConsumption: action.data,
        });
        break;
        case "GET_EXPENDITURE_VERTICAL":
        return Object.assign({}, state, {
          dayExpenditure: action.data,
        });
        break;
        case "GET_CONSUMPTION_PIE":
          return Object.assign({}, state, {
              monthConsumption: action.data,
          });
          break;
          case "GET_EXPENDITURE_PIE":
          return Object.assign({}, state, {
            monthExpenditure: action.data,
          });
          break;
          case "GET_CONSUMPTION_HORIZONTAL":
            return Object.assign({}, state, {
                horizontalConsumption: action.data,
            });
            break;
            case "GET_EXPENDITURE_HORIZONTAL":
            return Object.assign({}, state, {
              horizontalExpenditure: action.data,
            });
            break;
      default:
        return state;
    }
  };
  
  export default invoiceReducer;