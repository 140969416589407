import { Route, Navigate, Outlet  } from 'react-router-dom';
import axios from "axios";

const PrivateRoute = () => {
    const token = isAuthenticated();
  
    return token ?  <Navigate to="/dashboard/0" /> : <Navigate to="/home" />;
  };
  
  export default PrivateRoute;

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    // if(token){
    //   axios.defaults.headers.post["Authorization"] = `Bearer ${token}`;

    // }
    return token?true:false; // Placeholder value
  };
  export {isAuthenticated};