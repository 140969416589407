import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { ContentHeader, Header, Overlaydivsection } from "../../Components/header";


const RaceChart = (props) => {
  const [option, setOptions] = useState({});
  const [render, setRender] = useState(0);
  const [chartHeight, setChartHeight] = useState('100vh'); // Default to full screen

  useEffect(() => {
    let { type, key } = props.router.params;
    let { horizontalConsumption, horizontalExpenditure } = props;
    let data = type === "Consumption" 
      ? JSON.parse(JSON.stringify(horizontalConsumption)) 
      : JSON.parse(JSON.stringify(horizontalExpenditure));
    if(Object.keys(data).length){
    let jsonData = data[key];
    jsonData.sort((a, b) => a.percentage - b.percentage);
    const yAxisData = jsonData.map(item => item.name);
    const seriesData = jsonData.map(item => item.percentage);

    // Calculate chart height dynamically based on data length
    const calculatedHeight = `${yAxisData.length * 40}px`; // 40px per bar, adjust as needed
    setChartHeight(calculatedHeight);

    const option = {
      title: {
        text: ` Average ${type.toLowerCase()} of vegetables per day (${key})`,
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '5%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 1],
      },
      yAxis: {
        type: 'category',
        data: yAxisData,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          interval: 0, // Ensures all labels are displayed
        },
      },
      series: [
        {
          name: 'Value',
          type: 'bar',
          data: seriesData,
          barWidth: 30, // Set bar height
          itemStyle: {
            color: '#5470C6',
          },
        },
      ],
    };

    setOptions(option);
    setRender(render+1);
    // props.router.navigate('/dashboard/3', { replace: true });
    // window.history.pushState({}, '', "/dashboard/3");
  }
  }, [props]);

  return (
    <div  className="w-full">
      <ContentHeader />
     <Header />
    <div key={render} style={{ width: '100%', height: chartHeight, overflowY: 'auto', marginTop: '60px' }}>
    
      <ReactECharts option={option} style={{ height: '100%' }} />
    </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    horizontalConsumption: state.invoice.horizontalConsumption,
    horizontalExpenditure: state.invoice.horizontalExpenditure,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RaceChart));
