const initialState = {
    products: [],
    myOrders:[],
    orderIds:[],
    suggestions:[],
    clientDetails:{}
  };
  
  const productReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_CLIENT_DETAILS":
        return Object.assign({}, state, {
          clientDetails: action.data,
        });
        break;
        case "GET_SUGGESTIONS":
          return Object.assign({}, state, {
            suggestions: action.data,
          });
          break;
        case "GET_PRODUCTS":
          return Object.assign({}, state, {
            products: action.data,
          });
          break;
        case "GET_MY_ORDERS":
          return Object.assign({}, state, {
            myOrders: action.data,
          });
          break;
      case "GET__ORDERS_IDS":
        return Object.assign({}, state, {
          orderIds : action.data,
        });
        break;
        case "GET_DAY_DATA":
          return Object.assign({}, state, {
            dayData: action.data,
          });
          break;
      default:
        return state;
    }
  };
  
  export default productReducer;