import React, { useEffect, useState } from "react";
// import Tab from "@mui/material/Tab";
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import './customModal.css'; 
import withRouter from "../Router/withRouter";
import "../Auth/Orders.css";

import Pagination from "@mui/material/Pagination";
import {
  Table,
  TableBody,
  // TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Stack,
} from "@mui/material";
// import Stack from "@mui/material/Stack";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { GrFormNextLink } from "react-icons/gr";
import Cart from "./cart";
import { FaPlus, FaMinus, FaCheck } from "react-icons/fa";

import {
  getProducts,
  products,
  emptyCart,
} from "../../services/Actions/client";
import ModalPop from "./ModalPop";
// import




const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Orders(props) {
  const [rows, setRows] = useState([]);
  const [value, setValue] = useState("");
  const [isFirst, setFirst] = useState(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    setRows(props.products);
    setFirst(true);
  }, [props]);

  const handleQuantityChange = (index, change, item) => {
    let { dispatch } = props;

    const newRows = rows.map((row) =>
      row.id === item.id ? { ...row, qty: Math.max(1, row.qty + change), isUpdate:true } : row
    );

    dispatch({
      type: "GET_PRODUCTS",
      data: newRows,
    });

    setRows(newRows);
  };
const onFocusHandle=(e,row) => {
  if (e.target.value === "") {
    const newRows = rows.map((r) =>
      r.id === row.id ? { ...r, qty: 0, isUpdate:true } : r
    );

    props.dispatch({
      type: "GET_PRODUCTS",
      data: newRows,
    });

    setRows(newRows);
  }
}
  const handleInputChange = (event, row) => {
    const newQuantity =event.target.value==""? "": Math.max(0, Number(event.target.value) || 0);

    const newRows = rows.map((r) =>
      r.id === row.id ? { ...r, qty: newQuantity, isUpdate:true } : r
    );

    props.dispatch({
      type: "GET_PRODUCTS",
      data: newRows,
    });

    setRows(newRows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const onSearch = (e) => {
    let value = e.target.value;
    setValue(value);
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="w-full">
      <div className="container">
        <div className="row">
          <div className="productssection">
            <div className="col-lg-12 mb-3 d-flex align-items-center justify-content-between">
              <h3>Products</h3>
              {/* <button className="nav-button-section active p-2" variant="primary" >Suggestion</button> */}
              <ModalPop/>
            </div>
            <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5">
              <div className="inputseachsection">
                <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={onSearch}
                />
              </div>
              <div className="d-flex justify-content-end">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(rows
                      .filter((item) =>
                        value
                          ? item.name.toLowerCase().includes(value.toLowerCase())
                          : true
                      ).length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </div>
            </div>
            <TableContainer
              component={Paper}
              className="table-responsive"
              style={{ borderRadius: "0px" }}
            >
              <Table
                className="table table-bordered"
                style={{ marginBottom: "0px", border: "1px solid black" }}
                sx={{ minWidth: 700 }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Product</StyledTableCell>
                    <StyledTableCell align="center">Unit</StyledTableCell>
                    <StyledTableCell align="center">Quantity</StyledTableCell>
                    <StyledTableCell align="center">Availability</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .filter((item) =>
                      value
                        ? item.name.toLowerCase().includes(value.toLowerCase())
                        : true
                    )
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell component="th" scope="row" align="left">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.unit_name}</StyledTableCell>
                        <StyledTableCell align="center">
                          <div className="productquantity d-flex align-items-center justify-content-center">
                            <span>
                              <FaMinus
                                onClick={() => handleQuantityChange(index, -1, row)}
                                style={{
                                  cursor: row.qty > 1 ? "pointer" : "not-allowed",
                                  color: row.qty > 1 ? "black" : "grey",
                                }}
                              />
                            </span>
                            <span>
                              <input
                                type="number"
                                className="quantityinputsection"
                                value={row.qty}
                                onChange={(e) => handleInputChange(e, row)}
                                onBlur={(e) => onFocusHandle(e, row)}
                                // onFocusCapture={}
                                min="1"
                              />
                            </span>
                            <span>
                              <FaPlus
                                onClick={() => handleQuantityChange(index, 1, row)}
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                           {row.isUpdate&& <span>
                            <FaCheck
                                style={{ color: "green" }}
                              />
                            </span>}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.delivery_window}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="col-lg-12 my-5 d-flex justify-content-between">
              <div>
                <button
                  className="nav-button-section"
                  onClick={() => props.dispatch(emptyCart(rows))}
                >
                  Empty Shopping Cart
                </button>
              </div>
              <div className="viewshoppingcartsection">
                <h5
                  onClick={() =>
                    props.router.navigate("/dashboard/1", { replace: true })
                  }
                >
                  <a href>View Shopping Cart</a>
                  <span className="nextbuttonicon">
                    <GrFormNextLink />
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    products: state.product.products??[],
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));