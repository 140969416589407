import React, { useState, useEffect } from "react";
// import Tab from "@mui/material/Tab";

import { connect } from "react-redux";
import withRouter from "../Router/withRouter";

import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { LuDownload } from "react-icons/lu";
import { ContentHeader, Header } from "../../Components/header";
// import Calendar from "../Auth/calendar";
import DatePicker from 'react-datepicker';


import { getMyOrders } from "../../services/Actions/client";
import MyOrdersModal from "./myOrdersModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function MyOrders(props) {
  const [myOrders, setMyOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

  
  const [openModal, setOpenModal] = useState(false); // State to control modal
  const [selectedOrder, setSelectedOrder] = useState(null); // State to store selected order details

  useEffect(() => {
    getMyordersApi("","");
    // props.dispatch(getMyOrders("",""));
  }, []);

  useEffect(() => {
    setMyOrders(props.myOrders);
  }, [props]);
const getMyordersApi=(from,to)=>{
  props.dispatch(getMyOrders(from,to));
}
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
  };

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOrder(null);
  };

  
const handleMonthChange = (date,setDate) => {
  setDate(date)
};
const clearDate =()=>{
  setStartDate(null);
  setEndDate(null);
}
const applyFilter =()=>{
  const from = moment(startDate).format('YYYY-MM-DD');

  const to = moment(endDate).format('YYYY-MM-DD');

  getMyordersApi(from,to)
}
  return (
    <div className="w-full">
      <ContentHeader />
      <Header />
      <div className="container mt-5">
        <div className="row">
          <div className="productssection">
            <div className="col-lg-12 mb-3">
              <h3>My Orders</h3>
            </div>
            <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5">
              <div className="inputseachsection">
                {/* <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search by Date..."
                  aria-label="Search"
                  onChange={onSearch}
                /> */}
              </div>
              <div className="d-flex justify-content-end">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(
                      myOrders
                        .filter((item) =>
                          search
                            ? item.order_id
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              item.status
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            : true
                        ).length / rowsPerPage
                    )}
                    page={page}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5">
              {/* <div className="col-2">
                <h3>Select Date Range:</h3>
              </div> */}
              <div className="fromDateSection col-3 d-flex align-items-center me-5">
                <h5 className="me-3">From</h5>
                <DatePicker
                  selected={startDate}
                  placeholderText="dd/mm/yyyy"

                  onChange={(date) => handleMonthChange(date,setStartDate)}
                  // onYearChange={(date) => handleMonthChange(date)}

                  dateFormat="dd/MM/yyyy"
                  // showMonthYearPicker
                  className="form-control"
                />
              </div>
              <div className="toDateSection col-3 d-flex align-items-center">
                <h5 className="me-3">To</h5>
                <DatePicker
                  selected={endDate}
                  placeholderText="dd/mm/yyyy"
                  onChange={(date) => handleMonthChange(date,setEndDate)}
                  // onYearChange={(date) => handleMonthChange(date)}

                  dateFormat="dd/MM/yyyy"
                  // showMonthYearPicker
                  className="form-control"
                />
              </div>
              <div className="col-2  d-flex">
              <div className="col-6 d-flex justify-content-between align-items-center">
                  <button onClick={clearDate} className="button-section greyButton">Clear</button>
                </div>
                <div className="col-6 d-flex justify-content-between align-items-center">
                  <button onClick={applyFilter} className="button-section">Apply</button>
                </div>
                </div>
            </div>
            <TableContainer
              component={Paper}
              style={{ borderRadius: "0px" }}
            >
              <Table
                className="table table-bordered"
                style={{ border: "1px solid black" }}
                sx={{ minWidth: 700 }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Order Number</StyledTableCell>
                    <StyledTableCell align="center">
                      Date Placed
                    </StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">
                      Order Summary
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myOrders
                    .filter((item) =>
                      search
                        ? item.order_id
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          item.status
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        : true
                    )
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((row) => (
                      <StyledTableRow key={row.order_number}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="left"
                        >
                          {row.order_number}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.created_at}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div className="statussection delivered">
                            {row.status}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <button className="viewmodalbutton"
                            onClick={() => handleOpenModal(row)}
                          >
                            View
                            </button>
                            <span className="pl-2 download-icon">
                              <LuDownload height={60} width={60} />
                            </span>
                          
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {selectedOrder && (
        <MyOrdersModal open={openModal} handleClose={handleCloseModal} />
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    myOrders: state.product.myOrders??[],
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyOrders)
);
