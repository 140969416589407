import React from "react";
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import PrivateRoute from "./privateRoute";
import Check1 from "../check1";
import Landing from "../Auth/landing";
import Orders from "../Auth/orders";
import Cart from "../Auth/cart";
import Invoices from "../Auth/invoices";
import Analytics from "../Analytics/Analysis";
import Dashboards from "../Auth/dashboard";
import Profile from "../Auth/profile";
import Login from "../Auth/login";
// import MainLaunch from "../mainLaunch";
import MyOrder from "../Auth/myOrder";
import Stepper from "../Auth/stepper";
import RaceChart from "../Analytics/raceChart";

const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/" element={<PrivateRoute />}>
        {/* <Route path="/home" element={<Landing />} /> */}
        {/* <Route path="/orders" element={<Orders />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/dashboard/:tab" element={<Dashboards />} />
        <Route path="/profile" element={<Profile />} /> */}
        </Route>
        <Route path="/home" element={<Landing />} />
        {/* <Route path="/MainLaunch" element={<MainLaunch />} >


        </Route> */}
        <Route path="/MyOrders" element={<MyOrder />}/>

        <Route path="/orders" element={<Orders />} />
        <Route path="/cart" element={<Cart />} />
        {/* <Route path="/invoices" element={<Invoices />} /> */}
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/dashboard/:tab" element={<Dashboards />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/stepper" element={<Stepper />} />
        <Route path="/RaceChart/:type/:key" element={<RaceChart />} />
        <Route path="*" element={<h1>Page not found</h1>} />
      </Route>
    )
  );
  export default router;