import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";

function AnalysisPie(props){

  const [option, setOptions] = useState({});
      
  
  useEffect(() => {
    let { monthConsumption,monthExpenditure,type } = props;
    let source = [];
let data= type==="Consumption"? monthConsumption:monthExpenditure;

  //   if(type==="Consumption"){
  //      Object.keys(monthConsumption).map(day => {
  //       if(Array.isArray(monthConsumption[day])){
  //       const totalPercentage =monthConsumption[day]&& monthConsumption[day]?.reduce((sum, item) => sum + item.percentage, 0);
  //     const val=totalPercentage.toFixed(2);
      
  //      source.push({ name: `${day} ${val}% `, value: val });
  //       }
  //   });
  // }else{
  //     Object.keys(monthExpenditure).map(day => {
  //       if(Array.isArray(monthExpenditure[day])){
  //     const totalPercentage =monthExpenditure[day] && monthExpenditure[day]?.reduce((sum, item) => sum + item.percentage_to_price, 0);
  //     const val=totalPercentage.toFixed(2);
  //     source.push({ name:  `${day} ${val}% `, value: val });
  //       }
  // });
  // }
  const formattedData = Object.keys(data).map(key => ({
    name: `${key} ${data[key]?.toFixed(2)}%`,
    value: data[key].toString()
}));
  const  val = {
 

    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: formattedData
      }
    ]
  };
    setOptions(val);
  }, [props]);
   
      
    return(
        <div className="d-flex justify-content-center">
        <ReactEcharts option={option} style={{height:"600px" , width:'100%'}} />
      </div>
    )
}


function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    monthConsumption: state.invoice.monthConsumption,
    monthExpenditure: state.invoice.monthExpenditure,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalysisPie)
);