import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Carrot from "../../assets/cart/carrot.jpg";
import { GrFormClose } from "react-icons/gr";

export default function MyOrdersModal({ open, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className="myorderdetailsmodalpopupsection"
      fullWidth={true}
      maxWidth="md" // Set this to 'lg' or 'xl' for larger modal
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="orderdetailscontentheadingsection">
          <div className="orderdetailscontentheading d-flex">
            <h3 className="col-6">Order Number</h3>
            <h3 className="col-6">FT67390999094 </h3>
          </div>
          <div className="orderdetailscontentheading d-flex">
            <h3 className="col-6">Order Placed</h3>
            <h3 className="col-6">Sunday, 07/21/24 , 4:30 P.M </h3>
          </div>
          <div className="orderdetailscontentheading d-flex">
            <h3 className="col-6">Delivery Time</h3>
            <h3 className="col-6">Monday, 07/22/24 </h3>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className="orderdetailscontentbodysection">
            <div className="orderdetailscontentbody d-flex">
                <div className="col">
                    <image src={Carrot} alt="image" className="image-section"></image>
                    <div>
                        <h3>Carrots</h3>
                        <div>
                            <h6>Units : Kgs</h6>
                            <h6>24 hrs delivery</h6>
                        </div>
                    </div>                
                </div>
                <div className="col-2">
                    <input
                        type="text"
                        className="quantityvaluesection"
                        value={2}
                    ></input>
                </div>
            </div>
            <div className="orderdetailscontentbody d-flex">
                <div className="col">
                    <image src={Carrot} alt="image" className="image-section"></image>
                    <div>
                        <h3>Carrots</h3>
                        <div>
                            <h6>Units : Kgs</h6>
                            <h6>24 hrs delivery</h6>
                        </div>
                    </div>                
                </div>
                <div className="col-2">
                    <input
                        type="text"
                        className="quantityvaluesection"
                        value={2}
                    ></input>
                </div>
            </div>
            <div className="orderdetailscontentbody d-flex">
                <div className="col">
                    <image src={Carrot} alt="image" className="image-section"></image>
                    <div>
                        <h3>Carrots</h3>
                        <div>
                            <h6>Units : Kgs</h6>
                            <h6>24 hrs delivery</h6>
                        </div>
                    </div>                
                </div>
                <div className="col-2">
                    <input
                        type="text"
                        className="quantityvaluesection"
                        value={2}
                    ></input>
                </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end mt-5">
          <div className="totalquantitysection col-6">
            <div className="totalquantity d-flex">
                <h3 className="col-6">Receiving Dates:</h3>
                <h3 className="col-6 d-flex justify-content-end">Monday, 07/22/24</h3>
            </div>
            <div className="totalquantity d-flex">
                <h3 className="col-6">Total Line Items:</h3>
                <h3 className="col-6 d-flex justify-content-end">6</h3>
            </div>
            <div className="totalquantity d-flex">
                <h3 className="col-6">Total Quantity</h3>
                <h3 className="col-6 d-flex justify-content-end">14</h3>
            </div>
          </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} className="closebutton">
        <GrFormClose style={{color: "#fff", fontSize: "45px"}}/>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
