
import axios from 'axios';
import {API} from "../apis"

const service = axios.create({
  baseURL: API.BASE_URL,
  timeout: 10000,
});

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers['Content-Type']="application/json";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {

    return response;
  },
  async (error) => {

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem('refreshToken');
      try {
        const response = await axios.post(`${API.BASE_URL}api/v1/auth/refresh`, { refresh_token: refreshToken });
        const newToken = response?.data?.access_token??"";
        const newRefreshToken = response?.data?.refresh_token??"";
        localStorage.setItem('token', newToken);
        localStorage.setItem("refreshToken", newRefreshToken);

        service.defaults.headers.Authorization = `Bearer ${newToken}`;
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return service(originalRequest);
      } catch (err) {
        localStorage.clear();
        // Handle token refresh failure, possibly redirect to login
        window.location.href = '/login';
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default service;
