
import React, { useEffect } from "react";
import {
  RouterProvider
} from "react-router-dom";
import router from "./Pages/Router/router";
import axios from "axios";
import "./App.css"
import "./Orders.css"
import "./Stepper.css"
import "./Responsive.css"
import "./Profile.css"
import "./Responsive.css"
import "./login.css"
import {API} from "./services/apis"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// axios.defaults.baseURL=API.BASE_URL;
// axios.defaults.headers.post['Content-Type'] = 'application/json';
const App = () => {

  useEffect(()=>{
// axios.create({
//   baseURL: 'https://backend.api.k8s.farmaze.com/',
//   timeout: 1000,
//   headers: {'Content-Type': 'application/json'}
// });
  },[])
  return (
    <div>
     <RouterProvider
    router={router}
    fallbackElement={<div />}
  />
  <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
