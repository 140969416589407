
import ReactDOM from 'react-dom/client';
import * as React from "react";
import { Provider } from 'react-redux';
import store from './services/store';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from "./App";

import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Provider store={store}>
  <App />
  </Provider>
</React.StrictMode>
);
reportWebVitals();
